import omit from 'lodash/omit';

import { showErrorToastNow } from 'common/components/ToastNotification/Toastmaster';
import * as contractsApi from 'common/core/contracts.ts';
import * as frontendApi from 'common/frontend/internal';

import { MODES } from './components/ContractModal';

// Public members
export const fetchAllContracts = async (onSuccess) => {
  try {
    const contracts = await contractsApi.getAllContracts();
    const sorted = contracts.sort((a, b) => b.createdAt - a.createdAt);
    onSuccess(sorted);
  } catch (error) {
    console.error(`fetchAllContracts error: ${error}`);
    showErrorToastNow(error.message);
  }
};

export const fetchFeatureFlags = async (onSuccess) => {
  try {
    const featureFlagTypes = await frontendApi.featureFlagTypes();
    const featureFlags = Object
      .keys(featureFlagTypes)
      .map((k) => ({ name: k, types: featureFlagTypes[k] }));
    onSuccess(featureFlags);
  } catch (error) {
    console.error(`fetchFeatureFlags error: ${error}`);
    showErrorToastNow(error.message);
  }
};

export const fetchModules = async (onSuccess) => {
  try {
    const modules = await frontendApi.moduleList();
    onSuccess(modules);
  } catch (error) {
    console.error(`fetchModules error: ${error}`);
    showErrorToastNow(error.message);
  }
};

export const submitContract = async (contract, modalMode) => {
  if (modalMode === MODES.CREATE) {
    const resp = await createContract(contract);
    await createContractProvisions(contract);
    return resp;
  } else if (modalMode === MODES.EDIT) {
    const resp = await updateContract(contract);
    await replaceContractProvisions(resp.key, contract.provisions);
    return resp;
  }
};

// Helpers
const createContract = (contract) => contractsApi.createContract(omit(contract, 'currentKey'));

const createContractProvisions = async (contract) => {
  return await Promise.all(
    contract.provisions.map((provision) => {
      return contractsApi.createContractProvision(contract.key, provision);
    })
  );
};

const updateContract = (contract) =>
  contractsApi.updateContract(contract.currentKey, omit(contract, 'currentKey'));

const replaceContractProvisions = async (contractKey, provisions) =>
  contractsApi.replaceContractProvisions(contractKey, provisions);

