import React from 'react';
import { connect } from 'react-redux';

import { Input, TextArea } from 'common/components/Forms';

import Provisions from './Provisions';
import * as actions from '../actions';

const ContractSubmitForm = (props) => {
  const {
    modalContract,
    updateModalContract,
  } = props;

  const handleEvent = (keyToUpdate) => (event) =>
    updateModalContract({ [keyToUpdate]: event.target.value });

  return (
    <div className="contract-submit-form">
      <Input label="Name"
             name="contract-name"
             required={true}
             onChange={handleEvent('name')}
             value={modalContract.name} />
      <Input label="Key"
             name="contract-key"
             required={true}
             onChange={handleEvent('key')}
             value={modalContract.key} />
      <TextArea label="Description"
                name="contract-description"
                onChange={handleEvent('description')}
                value={modalContract.description} />
      <Provisions />
    </div>
  );
};

const mapStateToProps = state => ({
  modalContract: state.modalContract
});

const mapDispatchToProps = {
  updateModalContract: actions.updateModalContract,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractSubmitForm);
