import React from 'react';
import get from 'lodash/get';

import { Dropdown } from 'common/components/Dropdown';

const ModuleDesiredSetting = (props) => {
  const { provision, update } = props;

  const value = get(provision, 'desiredSetting', '');
  const options = [
    { title: 'Enabled', value: 'true' },
    { title: 'Disabled', value: 'false' },
  ];

  return (
    <Dropdown className="row-input module-desired-setting"
              options={options}
              value={value}
              onSelection={({ value: newVal }) => update({ desiredSetting: newVal })} />
  );
};

export default ModuleDesiredSetting;
