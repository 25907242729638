import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import Button from 'common/components/Button';
import { showErrorToastNow, showSuccessToastNow } from 'common/components/ToastNotification/Toastmaster';

import { MODES } from './ContractModal';

import * as actions from '../actions';
import * as api from '../api';

const SubmitContractButton = (props) => {
  const {
    hideModal,
    modalContract,
    modalMode,
    setContractsAndCache,
    validationErrors,
    showValidationErrors
  } = props;

  const onSubmit = async () => {
    if (Object.keys(validationErrors).length > 0) {
      showValidationErrors();
      return;
    }

    const action = modalMode === MODES.EDIT ?
      'updated' :
      'created';

    try {
      const normalizedContract = normalizeProvisions(modalContract);
      const resp = await api.submitContract(normalizedContract, modalMode);
      showSuccessToastNow(`Successfully ${action} contract '${resp.name}'`);
      await api.fetchAllContracts(setContractsAndCache);
      hideModal();
    } catch (error) {
      const resp = await error.response.json();
      console.error('onSubmit error: ', resp);
      showErrorToastNow(resp.message);
    }
  };

  return (
    <Button type="submit"
            className="contract-submit-button"
            variant="primary"
            onClick={onSubmit}>
      Submit
    </Button>
  );
};

const normalizeProvisions = (contract) => ({
  ...contract,
  provisions: (contract.provisions || []).map((provision) => ({
    ...provision,
    lookup: [provision.type, get(provision, 'parameterData.name')].join(':')
  }))
});

const mapStateToProps = state => ({
  modalContract: state.modalContract,
  modalMode: state.modalMode,
  validationErrors: state.validationErrors
});

const mapDispatchToProps = {
  setContractsAndCache: actions.setContractsAndCache,
  hideModal: actions.hideModal,
  showValidationErrors: actions.showValidationErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitContractButton);
