import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import intersection from 'lodash/intersection';

import { Dropdown } from 'common/components/Dropdown';

const FlagDesiredSetting = (props) => {
  const { featureFlags, provision, update } = props;

  const value = get(provision, 'desiredSetting', '');
  const types = typesFor(provision, featureFlags);

  const textTypes = intersection(['string', 'integer'], types);

  if (textTypes.length > 0) {
    return (
      <input type="text"
             className="row-input text-input flag-desired-setting"
             placeholder={`Enter ${textTypes.join(' or ')}`}
             onChange={(event) => update({ desiredSetting: event.target.value })}
             value={value}/>
    );
  } else {
    return (
      <Dropdown className="row-input flag-desired-setting"
                options={optionsFor(types)}
                value={value}
                onSelection={({ value: newVal }) => update({ desiredSetting: newVal })}/>
    );
  }
};

const typesFor = (provision, featureFlags) => {
  const matchingFlag = featureFlags.find((flag) => flag.name === get(provision, 'parameterData.name'));
  return get(matchingFlag, 'types', []);
};

const optionsFor = (types) => {
  if (types.includes('boolean')) {
    return [
      { title: 'True', value: 'true' },
      { title: 'False', value: 'false' },
    ];
  } else if (types.length === 1 && Array.isArray(types[0])) {
    return types[0].map((type) => ({ title: type, value: type }));
  } else {
    return [];
  }
};

const mapStateToProps = state => ({
  featureFlags: state.featureFlags,
});

export default connect(mapStateToProps)(FlagDesiredSetting);
