import * as actions from './actions';
import validateModalContract from './validateModalContract';

// Shape of the store
const initialState = {
  modalContract: {
    name: '',
    key: '',
    currentKey: '', // required to persist contracts with an updated key
    description: '',
    provisions: []
  },
  modalHidden: true,
  modalMode: '',
  contracts: [],
  contractsCached: [],
  featureFlags: [],
  modules: [],
  validationErrors: {},
  showValidationErrors: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_MODAL_CONTRACT:
      return {
        ...state,
        ...validateModalContract(state.modalContract, action.updates)
      };
    case actions.SHOW_MODAL:
      return {
        ...state,
        modalHidden: false,
        modalMode: action.modalMode,
        ...validateModalContract(action.modalContract || initialState.modalContract)
      };
    case actions.HIDE_MODAL:
      return { ...state, modalHidden: true, showValidationErrors: false };
    case actions.SET_CONTRACTS:
      return { ...state, contracts: action.contracts };
    case actions.SET_CONTRACTS_AND_CACHE:
      return {
        ...state,
        contractsCached: action.contractsCached,
        contracts: action.contractsCached
      };
    case actions.SET_FEATURE_FLAGS:
      return { ...state, featureFlags: action.featureFlags };
    case actions.SET_MODULES:
      return { ...state, modules: action.modules };
    case actions.SHOW_VALIDATION_ERRORS:
        return { ...state, showValidationErrors: true };
    case actions.ADD_PROVISION: {
      const existingProvisions = state.modalContract.provisions || [];
      return {
        ...state,
        ...validateModalContract(state.modalContract, { provisions: [ ...existingProvisions, {} ] })
      };
    }
    case actions.REMOVE_PROVISION: {
      const updated = Array.from(state.modalContract.provisions);
      updated.splice(action.index, 1);
      return {
        ...state,
        ...validateModalContract(state.modalContract, { provisions: updated })
      };
    }
    case actions.UPDATE_PROVISION: {
      const updated = Array.from(state.modalContract.provisions);
      updated[action.index] = { ...updated[action.index], ...action.updates }; // allows for partial updates
      return {
        ...state,
        ...validateModalContract(state.modalContract, { provisions: updated })
      };
    }
    default:
      return state;
  }
};
