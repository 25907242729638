import React from 'react';

import FlagDesiredSetting from './FlagDesiredSetting';
import ModuleDesiredSetting from './ModuleDesiredSetting';

const ProvisionDesiredSetting = (props) => {
  const {
    provision,
    update
  } = props;

  return provision.type === 'flag' ?
    <FlagDesiredSetting provision={provision} update={update} /> :
    <ModuleDesiredSetting provision={provision} update={update} />;
};

export default ProvisionDesiredSetting;
