import React from 'react';
import { connect } from 'react-redux';

import Modal, { ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';

import ContractSubmitForm from './ContractSubmitForm';
import SubmitContractButton from './SubmitContractButton';
import ValidationErrors from './ValidationErrors';

import * as actions from '../actions';

export const MODES = {
  EDIT: 'Edit',
  CREATE: 'Create'
};

class ContractModal extends React.Component {
  render() {
    const {
      modalHidden,
      modalMode,
      onDismiss,
    } = this.props;

    if (modalHidden) {
      return null;
    }

    const title = `${modalMode} Contract`;

    const modalProps = {
      onDismiss,
      className: 'contract-modal',
      overlay: true
    };

    return (
      <Modal {...modalProps}>
        <ModalHeader title={title} onDismiss={onDismiss}/>
        <ModalContent className="contract-modal-content">
          <ContractSubmitForm />
        </ModalContent>
        <ModalFooter>
          <ValidationErrors />
          <SubmitContractButton />
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  modalMode: state.modalMode,
  modalHidden: state.modalHidden
});

const mapDispatchToProps = {
  onDismiss: actions.hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractModal);
