import React from 'react';
import { connect } from 'react-redux';

import CachedAutocomplete from 'common/components/CachedAutocomplete';

import * as actions from '../actions';

const ContractAutocomplete = (props) => {
  const {
    contractsCached,
    setContracts
  } = props;

  const searchBarProps = {
    dataCached: contractsCached,
    onQuerySubmit: setContracts,
    onClearSearch: () => setContracts(contractsCached), // resets table
    placeholder: 'Find a Contract',
    className: 'contract-autocomplete',
    searchKeys: ['name', 'description']
  };

  return <CachedAutocomplete {...searchBarProps} />;
};

const mapStateToProps = state => ({
  contractsCached: state.contractsCached
});

const mapDispatchToProps = {
  setContracts: actions.setContracts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractAutocomplete);
