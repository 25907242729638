import React from 'react';
import get from 'lodash/get';

import { Dropdown } from 'common/components/Dropdown';
import Button from 'common/components/Button';

import ProvisionName from './ProvisionName';
import ProvisionDesiredSetting from './ProvisionDesiredSetting';

const Provision = (props) => {
  const {
    provision,
    remove,
    update
  } = props;

  const buttonProps = {
    className: 'remove-provision row-input',
    variant: 'transparent',
    onClick: remove
  };

  const dropdownProps = {
    options: [
      { title: 'Feature Flag', value: 'flag' },
      { title: 'Module', value: 'module' },
    ],
    value: provision.type,
    onSelection: (optionObj) => update({ type: optionObj.value, parameterData: undefined, desiredSetting: undefined }),
    className: 'provision-type-dropdown row-input'
  };

  return (
    <li className="provision-row">
      <Button {...buttonProps}>X</Button>
      <Dropdown {...dropdownProps} />
      {provision.type && <ProvisionName provision={provision} update={update} />}
      {get(provision, 'parameterData.name') &&
        <ProvisionDesiredSetting provision={provision} update={update} />}
    </li>
  );
};

export default Provision;
