import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import Button from 'common/components/Button';
import ResultsTable from 'common/components/ResultsTable';

import { MODES } from './ContractModal';
import * as actions from '../actions';

const ContractsTable = (props) => {
  const {
    contracts,
    showModal
  } = props;

  const renderActions = (ignored, contractRecord) => {
    const editButtonProps = {
      onClick: () => showModal(MODES.EDIT, contractRecord),
      className: 'edit-gate',
      variant: 'default'
    };

    return <Button {...editButtonProps}>Edit Contract</Button>;
  };

  const renderCreatedBy = (createdObj) => get(createdObj, 'by.email', 'UNKNOWN');

  return (
    <div className="contracts-table-container">
      <ResultsTable data={contracts}
                    noResultsMessage={''}
                    rowKey='id'>
        <ResultsTable.Column dataIndex='name'
                             header='Name' />
        <ResultsTable.Column dataIndex='key'
                             header='Key' />
        <ResultsTable.Column dataIndex='description'
                             header='Description' />
        <ResultsTable.Column dataIndex='created'
                             header='Created By'>
          {renderCreatedBy}
        </ResultsTable.Column>
        <ResultsTable.Column dataIndex='id'
                             header='Actions'
                             dataClassName='actions-cell'>
          {renderActions}
        </ResultsTable.Column>
      </ResultsTable>
    </div>
  );
};

const mapStateToProps = state => ({
  contracts: state.contracts
});

const mapDispatchToProps = {
  showModal: actions.showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractsTable);
