import React from 'react';
import { connect } from 'react-redux';

const ValidationErrors = (props) => {
  const {
    validationErrors,
    showValidationErrors
  } = props;

  if (!showValidationErrors) {
    return null;
  }

  const provisionElements = validationErrors
    .map((errMessage, idx) => <li key={idx}>{errMessage}</li>);

  return (
    <ul className="validation-errors">
      {provisionElements}
    </ul>
  );
};

const mapStateToProps = (state) => ({
  validationErrors: state.validationErrors,
  showValidationErrors: state.showValidationErrors
});

export default connect(mapStateToProps)(ValidationErrors);
