import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import ConfigContracts from './components/ConfigContracts';
import configContractsReducer from './reducer';
import './config-contracts.scss';

ReactDOM.render(
  <Provider store={createStore(configContractsReducer)}>
    <ConfigContracts />
  </Provider>,
  document.querySelector('#config-contracts-index')
);
