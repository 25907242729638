import get from 'lodash/get';
import { isDefined } from 'common/functional_helpers';

export default (currentModalContract, updates = {}) => {
  const modalContract = {
    currentKey: currentModalContract.key, // required for updates
    ...currentModalContract,
    ...updates
  };

  return {
    modalContract,
    validationErrors: validationErrorsFor(modalContract)
  };
};

const validationErrorsFor = (modalContract) => {
  return Object.values(VALIDATIONS)
    .map((validation) => {
      if (!validation.check(modalContract)) {
        return validation.message;
      }
    })
    .filter(Boolean);
};

const VALIDATIONS = {
  NAME_PRESENT: {
    check: (contract) => get(contract, 'name', '').length > 0,
    message: 'Field "Name" must be present'
  },
  KEY_VALID: {
    check: (contract) => /^[0-9a-zA-Z_-]+$/.test(get(contract, 'key', '')),
    message: 'Field "Key" must be present and contain only alphanumeric, underscore, and dash chars'
  },
  PROVISIONS_VALID: {
    check: (contract) => (contract.provisions || []).every((provision) => {
      return [
        provision.type,
        provision.parameterData,
        provision.desiredSetting
      ].every(isDefined);
    }),
    message: 'All Provisions must have type, name, and expected value'
  }
};
