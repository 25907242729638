import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

import Button from 'common/components/Button';
import CachedAutocomplete from 'common/components/CachedAutocomplete';

const ProvisionName = (props) => {
  const {
    featureFlags,
    modules,
    selectedProvisions,
    provision,
    update
  } = props;

  const name = get(provision, 'parameterData.name');
  const clearName = () => update({ parameterData: undefined, desiredSetting: undefined });
  const updateName = (results) => update({ parameterData: { name: results[0].name } });

  const availableProvisionsFor = (provisionType) => {
    const unselected = unselectedProvision(selectedProvisions, provisionType);
    switch (provisionType) {
      case 'flag':
        return featureFlags.filter(unselected);
      case 'module':
        return modules.filter(unselected);
      default:
        return [];
    }
  };

  if (name) {
    return (
      <div className="row-input provision-name-input">
        <span className="provision-name">{name}</span>
        <Button variant="transparent" onClick={clearName}>X</Button>
      </div>
    );
  }

  const searchBarProps = {
    dataCached: availableProvisionsFor(provision.type),
    onQuerySubmit: (results) => updateName(results),
    onClearSearch: () => update({ parameterData: undefined }),
    focusFirstResult: true,
    placeholder: `Find a ${capitalize(provision.type)}`,
    className: `find-a-${provision.type} find-a-provision row-input`,
    searchKeys: [ 'name' ]
  };

  return <CachedAutocomplete {...searchBarProps} />;
};

const mapStateToProps = state => ({
  selectedProvisions: state.modalContract.provisions,
  featureFlags: state.featureFlags,
  modules: state.modules
});

const unselectedProvision = (selectedProvisions, provisionType) => (provisionUnderTest) =>
  !selectedProvisions
    .filter((provision) => provision.type === provisionType)
    .map((provision) => get(provision, 'parameterData.name'))
    .includes(provisionUnderTest.name);

export default connect(mapStateToProps)(ProvisionName);
