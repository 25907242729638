import React from 'react';
import { connect } from 'react-redux';

import Button from 'common/components/Button';

import Provision from './Provision';

import * as actions from '../actions';

class Provisions extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.provisions.length < this.props.provisions.length) {
      document.querySelector('.contract-modal-content').scrollTop = 90000; // scroll all the way, baby
    }
  }

  render() {
    const {
      provisions,
      addProvision,
      removeProvision,
      updateProvision
    } = this.props;

    const provisionElements = (provisions || []).map((provision, index) => {
      const remove = () => removeProvision(index);
      const update = (updates) => updateProvision(index, updates);
      return (
        <Provision key={index}
                   provision={provision}
                   remove={remove}
                   update={update} />
      );
    });

    return (
      <div>
        <ul className="contract-provisions-list">
          {provisionElements}
        </ul>
        <Button className="add-provision" onClick={addProvision}>+ Add Provision</Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  provisions: state.modalContract.provisions || []
});

const mapDispatchToProps = {
  addProvision: actions.addProvision,
  removeProvision: actions.removeProvision,
  updateProvision: actions.updateProvision
};

export default connect(mapStateToProps, mapDispatchToProps)(Provisions);
