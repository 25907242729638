export const SHOW_MODAL = 'SHOW_MODAL';
export const showModal = (modalMode, modalContract) => ({
  type: SHOW_MODAL,
  modalMode,
  modalContract
});

export const HIDE_MODAL = 'HIDE_MODAL';
export const hideModal = () => ({ type: HIDE_MODAL });

export const UPDATE_MODAL_CONTRACT = 'UPDATE_MODAL_CONTRACT'; // allows for partial or full updates
export const updateModalContract = (updates) => ({ type: UPDATE_MODAL_CONTRACT, updates });

export const SET_CONTRACTS = 'SET_CONTRACTS';
export const setContracts = (contracts) => ({ type: SET_CONTRACTS, contracts });

export const SET_CONTRACTS_AND_CACHE = 'SET_CONTRACTS_AND_CACHE';
export const setContractsAndCache = (contractsCached) => ({ type: SET_CONTRACTS_AND_CACHE, contractsCached });

export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';
export const setFeatureFlags = (featureFlags) => ({ type: SET_FEATURE_FLAGS, featureFlags });

export const SET_MODULES = 'SET_MODULES';
export const setModules = (modules) => ({ type: SET_MODULES, modules });

export const SHOW_VALIDATION_ERRORS = 'SHOW_VALIDATION_ERRORS';
export const showValidationErrors = () => ({ type: SHOW_VALIDATION_ERRORS });

export const ADD_PROVISION = 'ADD_PROVISION';
export const addProvision = () => ({ type: ADD_PROVISION });

export const REMOVE_PROVISION = 'REMOVE_PROVISION';
export const removeProvision = (index) => ({ type: REMOVE_PROVISION, index });

export const UPDATE_PROVISION = 'UPDATE_PROVISION';
export const updateProvision = (index, updates) => ({ type: UPDATE_PROVISION, index, updates });
