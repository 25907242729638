import React from 'react';
import { connect } from 'react-redux';

import Button from 'common/components/Button';

import ContractModal, { MODES } from './ContractModal';
import ContractsTable from './ContractsTable';
import ContractAutocomplete from './ContractAutocomplete';

import * as actions from '../actions';
import * as api from '../api';

class ConfigContracts extends React.Component {
  componentDidMount() {
    const {
      setContractsAndCache,
      setFeatureFlags,
      setModules
    } = this.props;

    return Promise.all([
      api.fetchAllContracts(setContractsAndCache),
      api.fetchFeatureFlags(setFeatureFlags),
      api.fetchModules(setModules)
    ]);
  }

  render() {
    const createButtonProps = {
      onClick: () => this.props.showModal(MODES.CREATE),
      className: 'create-contract',
      variant: 'primary'
    };

    return (
      <div>
        <ContractModal />
        <h2 className="config-contracts-title">Config Contracts</h2>
        <Button {...createButtonProps}>Create Contract</Button>
        <ContractAutocomplete />
        <ContractsTable />
      </div>
    );
  }
}

const mapDispatchToProps = {
  setContractsAndCache: actions.setContractsAndCache,
  setFeatureFlags: actions.setFeatureFlags,
  setModules: actions.setModules,
  showModal: actions.showModal,
};

export default connect(null, mapDispatchToProps)(ConfigContracts);
